<template>
  <div class="container" id="container">
    <div class="filter">
      <ul>
        <a href="javascript:;" v-for="(item,index) in filter" :key="index" :class="checkedFilter.code == item.code ? 'active' : ''" @click="checkType(item)">{{item.name}}<i></i></a>
      </ul>
    </div>
    <div :class="edit ? 'list edit' : 'list'">
      <div class="nodata" v-show="list.length < 1"><img src="../assets/img/nodata.png" alt="暂无数据" /></div>
      <div class="item" v-for="(item, index) in list" :key="index" @click="go(item.bizId)">
        <div class="checkbox" :class="isChecked(item) ? 'checked' : ''" @click="check(item)"><i></i></div>
        <div class="box">
            <img :src="item.baseCover" :alt="item.baseTitle" />
            <div class="info">
              <h1>{{item.baseTitle}}</h1>
              <p>★★★★★</p>
              <div class="tag"><span>收藏时间：{{item.collectDate.substr(0,10)}}</span></div>
            </div>
        </div>
      </div>
    </div>
    <div class="manage">
      <a class="toedit" :class="edit ? 'editnow' : ''" href="javascript:;" @click="showEdit">管理</a>
      <div class="btns">
        <a href="javascript:;" @click="checkall" v-if="checkedList.length < list.length">全选</a>
        <a href="javascript:;" @click="clearall" v-else>反选</a>
        <a href="javascript:;" @click="del">删除</a>
        <a href="javascript:;" @click="edit = false">取消</a>
      </div>
    </div>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  export default {
    name: 'Collection',
    data () {
      return{
        filter: [
          {name: '全部', code: null},
          {name: '商品', code: 4},
          {name: '商家', code: 3}
        ], // 收藏类型筛选列表
        checkedFilter: {}, // 选择的收藏类型
        edit: false, // 编辑状态
        loading: false, // 正在加载
        pageCount: 1, // 最大页数
        pageIndex: 1, // 当前页码
        pageSize: 10, // 每页数量
        list: [], // 收藏列表
        checkedList: [] // 选择要删除的收藏
      }
    },
    mounted(){
      console.log('我的收藏')
      //监听scroll事件
      window.addEventListener('scroll', this.handleScroll, true)
      this.checkedFilter = this.filter[0] // 默认全部类型收藏
      this.getMyCollection()
    },
    destroyed() {
      //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题
      window.removeEventListener('scroll', this.handleScroll)
    },
    created(){

    },
    methods: {
      // 收藏类型筛选
      checkType(type) {
        if(this.checkedFilter != type){
          this.edit = false // 关闭管理样式
          this.checkedFilter = type
          this.pageIndex = 1
          this.list = []
          this.getMyCollection()
        }
      },
      // 获取我的收藏
      getMyCollection() {
        if(this.loading) return
        this.loading = true
        let me = this
        this.$post({
          url: 'userCollect/getMyCollect',
          params: {
            token: this.$global.token,
            bizType: this.checkedFilter.code,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            sortName: '',
            sortOrder: ''
          }
        }).then( rsp => {
          console.log(rsp.data)
          me.loading = false
          me.list = me.list.concat(rsp.data.data)
          me.pageCount = Math.ceil(rsp.data.count / me.pageSize)
        }).catch( error => {
          console.log(error)
          me.loading = false
          Toast(error.message)
        })
      },
      // 显示管理
      showEdit() {
        if(this.list.length < 1) {
          Toast('无可管理数据')
          return
        }
        this.checkedList = []
        this.edit = true
      },
      // 选择要删除的收藏
      check(item) {
        let i, hasItem = false, temporary = [];
        for(i in this.checkedList){
          if(this.checkedList[i] == item){
            hasItem = true
            continue
          }
          temporary.push(this.checkedList[i])
        }
        if(hasItem){
          this.checkedList = temporary
        }else{
          this.checkedList.push(item)
        }
      },
      // 是否选中
      isChecked(item){
        let i;
        for(i in this.checkedList){
          if(this.checkedList[i] == item){
            return true
          }
        }
        return false
      },
      // 全选
      checkall() {
        this.checkedList = []
        let i;
        for(i in this.list){
          this.checkedList.push(this.list[i])
        }
      },
      // 反选
      clearall() {
        let temporary = [];
        let i;
        for(i in this.list){
          if(!this.isChecked(this.list[i])){
            temporary.push(this.list[i])
          }
        }
        this.checkedList = temporary
      },
      // 删除
      del() {
        if(this.checkedList.length < 1){
          Toast('请选择需要删除的收藏')
        }else{
          let i, collectId = [], me = this;
          for(i in this.checkedList){
            collectId.push(this.checkedList[i].collectId)
            collectId.push(';')
          }
          collectId = collectId.join('')
          collectId = collectId.substr(0, collectId.length -1)
          this.$post({
            url: 'userCollect/cancelMyCollectList',
            params: {
              token: this.$global.token,
              collectId: collectId
            }
          }).then( rsp => {
            if(rsp.status == 1){
              me.edit = false
              me.checkedList = []
              me.list = []
              me.pageIndex = 1
              me.getMyCollection()
              Toast('删除成功！')
            }

          }).catch( error => {
            console.log(error)
            Toast(error.message)
          })
        }
      },
      // 查看收藏详情
      go(id) {
        this.$router.push({
          path:'/tiketDetails/' + id
        })
      },
      // 监听滚动条
      handleScroll() {
        let scrollTop = document.getElementsByTagName('body')[0].scrollTop
        let windowHeight = document.getElementById('app').clientHeight
        let bodyHeight = document.getElementById('container') ? document.getElementById('container').clientHeight - 10 : 0;
        if(scrollTop + windowHeight >= bodyHeight && !this.loading && this.pageIndex < this.pageCount){
          this.pageIndex++
          this.getMyCollection()
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 50px 0 60px 0;
    box-sizing: border-box;
    .filter{
      background: #fff;
      position: fixed;
      width: 100%;
      top: 0px;
      height: 50px;
      line-height: 50px;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      z-index: 1;
      font-size: 0;
      box-shadow: 0 0 5px #ccc;
      ul {
        height: 60px;
        padding: 0 20px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-x: auto;
      }
      a {
        font-size: 15px;
        color: #444;
        margin-left: 20px;
        display: inline-block;
        position: relative;
        transition: all 0.25s linear;
        i {
          position: absolute;
          width: 0;
          left: 50%;
          bottom: 8px;
          height: 4px;
          background: linear-gradient(to right, #7ddb4f, #21dd53);
          transition: all 0.25s linear;
        }
      }
      a.active{
        color: #000;
        font-size: 18px;
        i {
          width: 100%;
          left: 0;
        }
      }
      a:first-of-type {
        margin-left: 0;
      }
    }
    .list {
      border-top: 10px solid #f7f7f7;
      .nodata{
        height: calc(100vh - 120px);
        display: flex;
        align-items: center;/*垂直居中*/
        justify-content: center;/*水平居中*/
        img{
          width: 50%;
        }
      }
      .item{
        background: #fff;
        width: 100%;
        display: inline-block;
        overflow: hidden;
        padding: 0 20px;
        box-sizing: border-box;
        position: relative;
        .box{
          width: 100%;
          transition: all 0.25s linear;
          position: relative;
          left: 0;
          padding: 15px 0;
          border-bottom: 1px solid #eee;
          img{
            width: 120px;
            height: 80px;
            vertical-align: top;
            margin-right: 10px;
          }
          .info{
            width: calc(100% - 130px);
            height: 80px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            h1{
              font-size: 16px;
              color: #444;
              line-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            p{
              font-size: 14px;
              color: #bbb;
            }
            .tag{
              position: absolute;
              left: 0;
              bottom: 0;
              font-size: 12px;
              color: #666;
              span{
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .edit{
      .item{
        .checkbox {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #666;
          position: absolute;
          left: 20px;
          top: 20px;
          cursor: pointer;
        }
        .checked{
          background: url(../assets/img/icon-checked.png) no-repeat center center/70% auto #ff3e6d;
          border-color: #ff3e6d;
        }
        .box{
          left: 40px;
        }
      }
    }
    .manage{
      width: 100%;
      height: 50px;
      background: #fff;
      position: fixed;
      left: 0;
      bottom: 0;
      box-shadow: 0 0 5px #ccc;
      a.toedit{
        display: block;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(to right, #7ddb4f, #21dd53);
        color: #fff;
        text-align: center;
        font-size: 18px;
        transition: all 0.25s linear;
      }
      a.editnow{
        height: 0;
      }
      .btns{
        display: flex;
        justify-content: space-between;
        a{
          font-size: 16px;
          line-height: 50px;
          margin: 0 20px;
          color: #333;
        }
      }
    }
  }
</style>
